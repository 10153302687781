import React from 'react';
import FilterDropdown, { DARK_THEME, FilterDropdownTheme } from 'components/search-bar/advanced-search/filter-dropdown';
import FilterDropdownPropertyType from 'components/search-bar/advanced-search/filter-dropdown/home-type';
import { searchFilterIds } from 'constants/test-constants';
import { PropertyTypeOption, legacyPropertyTypeOptions } from 'utils/select-options';

import type { PropertyTypeFilter } from 'contexts/preferences/listing-params/types';

//#region Types
export interface HomeTypeFilterDropdownProps {
    propertyType: PropertyTypeFilter;
    propertyTypeFilterLabel: string;
    propertyTypeList?: PropertyTypeOption[];
    theme?: FilterDropdownTheme;
    onValueChange(value: PropertyTypeFilter): void;
}
//#endregion

const PropertyTypeFilterDropdown = ({ propertyType, propertyTypeFilterLabel, onValueChange, theme = DARK_THEME, propertyTypeList = legacyPropertyTypeOptions }: HomeTypeFilterDropdownProps) => {

  return (
    <FilterDropdown
      theme={theme}
      label={propertyTypeFilterLabel}
      testId={searchFilterIds.homeTypeDropdownMap}
    >
      <FilterDropdownPropertyType
        currentHomeTypeFilter={propertyType}
        onValueChange={onValueChange}
        propertyTypeList={propertyTypeList}
      />
    </FilterDropdown>
  );
};

export default PropertyTypeFilterDropdown;