import {
  FilterKeys,
  SLUG_PROPERTY,
  LATITUDE_PROPERTY,
  LONGITUDE_PROPERTY,
  ZOOM_PROPERTY,
  AREA_NAME_PROPERTY,
  BOUNDARY_PROPERTY,
} from 'contexts/preferences/listing-params';

export const IGNORED_FILTER_PROPERTIES: readonly FilterKeys[] = [
  SLUG_PROPERTY,
  LATITUDE_PROPERTY,
  LONGITUDE_PROPERTY,
  ZOOM_PROPERTY,
  AREA_NAME_PROPERTY,
  BOUNDARY_PROPERTY,
];