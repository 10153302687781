import React, { useMemo } from 'react';
import styles from './style.module.scss';
import FilterDropdown, { DARK_THEME, FilterDropdownTheme } from 'components/search-bar/advanced-search/filter-dropdown';
import FilterDropdownPriceRange from 'components/search-bar/advanced-search/filter-dropdown/price-range';
import formatPriceRange from 'utils/format-price';
import { searchFilterIds } from 'constants/test-constants';

//#region Types
export interface PriceRangeFilterDropdownProps {
    min: number | null;
    max: number | null;
    rental: boolean;
    theme?: FilterDropdownTheme;
    onValueChange: (min: number | null, max: number | null) => void;
}
//#endregion

const PriceRangeFilterDropdown = ({ min: priceMin, max: priceMax, rental, onValueChange, theme = DARK_THEME }: PriceRangeFilterDropdownProps) => {
  
  const priceRangeFilterLabel = useMemo(() => {
    return formatPriceRange(priceMin, priceMax);
  }, [priceMin, priceMax]);

  return (
    <FilterDropdown
      theme={theme}
      label={priceRangeFilterLabel}
      testId={searchFilterIds.priceRangeDropdownMap}
      contentClassName={styles['custom-panel-style']}
    >
      <FilterDropdownPriceRange
        min={priceMin}
        max={priceMax}
        isRental={rental}
        onValueChange={onValueChange}
      />
    </FilterDropdown>
  );
};

export default PriceRangeFilterDropdown;