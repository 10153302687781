import React, { useMemo } from 'react';
import FilterDropdown, { DARK_THEME, FilterDropdownTheme } from 'components/search-bar/advanced-search/filter-dropdown';
import ButtonGroup from 'components/control/button-group';
import { getListingStatusLabel } from 'utils/listing-query-helper';
import { searchFilterIds } from 'constants/test-constants';
import { statusOptions } from 'utils/select-options';

import type { Status } from 'contexts/preferences/listing-params/types';

//#region Types
export interface ListingStatusFilterDropdownProps {
    rental: boolean;
    status: Status;
    theme?: FilterDropdownTheme;
    onValueChange(value: Status): void;
}
//#endregion

const ListingStatusFilterDropdown = ({ rental, status, onValueChange, theme = DARK_THEME }: ListingStatusFilterDropdownProps) => {
  const searchStatusOptions = rental ? statusOptions(true, true) : statusOptions(false, true);

  const listingStatusFilterLabel = useMemo(() => {
    return `${getListingStatusLabel(status, rental)} Listings`;
  }, [rental, status]);

  return (
    <FilterDropdown
      theme={theme}
      label={listingStatusFilterLabel}
      testId={searchFilterIds.listingStatusDropdownMap}
    >
      <ButtonGroup
        value={status}
        onValueChange={onValueChange}
        options={searchStatusOptions}
        size={'large'}
      />
    </FilterDropdown>
  );
};

export default ListingStatusFilterDropdown;